import * as React from "react"
import { useState, useEffect, useRef } from "react"
import Seo from "../components/seo"
import { IoMedalOutline } from "react-icons/io5"

const MysteryBoxGamePage = () => {
  const [rows, setRows] = useState([
    { boxNumber: 1, guess: "", finalAnswer: "", isGreen: false },
    { boxNumber: 2, guess: "", finalAnswer: "", isGreen: false },
    { boxNumber: 3, guess: "", finalAnswer: "", isGreen: false },
    { boxNumber: 4, guess: "", finalAnswer: "", isGreen: false },
    { boxNumber: 5, guess: "", finalAnswer: "", isGreen: false },
  ])

  const [timeLeft, setTimeLeft] = useState(0)
  const [selectedTime, setSelectedTime] = useState(0)
  const [isRunning, setIsRunning] = useState(false)
  const timerRef = useRef(null)

  const handleInputChange = (index, field, value) => {
    const newRows = [...rows]
    newRows[index][field] = value
    setRows(newRows)
  }

  const handleButtonClick = index => {
    const newRows = [...rows]
    newRows[index].isGreen = !newRows[index].isGreen
    setRows(newRows)
  }

  const startTimer = () => {
    setIsRunning(true)
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
    timerRef.current = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current)
          setIsRunning(false)
          return 0
        }
        return prev - 1
      })
    }, 1000)
  }

  const pauseTimer = () => {
    setIsRunning(false)
    clearInterval(timerRef.current)
  }

  const resetTimer = () => {
    setIsRunning(false)
    clearInterval(timerRef.current)
    setTimeLeft(0)
    setSelectedTime(0)
  }

  const handleTimeSelection = time => {
    setSelectedTime(time)
    setTimeLeft(time)
  }

  useEffect(() => {
    return () => clearInterval(timerRef.current)
  }, [])

  return (
    <div className="bg-white dark:bg-zinc-900 pt-1 pb-6 p-2">
      <Seo
        title={"Mystery Box Game"}
        description="A fun and engaging mystery box guessing game"
        defer={false}
      />
      <div className="container mx-auto flex mt-8">
        {/* Left Section: Mystery Box Game Table */}
        <section className="w-2/3 rounded text-black dark:text-white">
          <div className="">
            <img
              src="https://cdn.sanity.io/images/rbhjt84j/production/dc1bdfaeebce3bfddf745df7ca22bfa4c27de393-1457x623.png"
              className="w-full rounded-t-xl mb-0"
            />
            <table className="w-full table-auto rounded-b-xl border-collapse">
              <thead>
                <tr className="bg-[#45AEB7] dark:bg-zinc-800">
                  <th className="border  dark:border-gray-600 px-2 py-2">
                    Box Number
                  </th>
                  <th className="border  dark:border-gray-600 px-2 py-2">
                    Guess
                  </th>
                  <th className="border  dark:border-gray-600 px-2 py-2">
                    Final Answer
                  </th>
                  <th className="border  dark:border-gray-600 px-2 py-2">?</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr
                    key={index}
                    className={`text-center ${
                      row.isGreen ? "bg-green-500" : ""
                    }`}
                  >
                    <td className="border  dark:border-gray-600 px-2 py-2 font-bold">
                      Box {row.boxNumber}
                    </td>
                    <td className="border dark:border-gray-500 px-2 py-2">
                      <input
                        type="text"
                        value={row.guess}
                        onChange={e =>
                          handleInputChange(index, "guess", e.target.value)
                        }
                        className="w-full p-1"
                      />
                    </td>
                    <td className="border  dark:border-gray-600 px-2 py-2">
                      <input
                        type="text"
                        value={row.finalAnswer}
                        onChange={e =>
                          handleInputChange(
                            index,
                            "finalAnswer",
                            e.target.value
                          )
                        }
                        className="w-full p-1"
                      />
                    </td>
                    <td className="border  dark:border-gray-600 px-2 py-2 ">
                      <button
                        onClick={() => handleButtonClick(index)}
                        className="dark:bg-zinc-600 bg-gray-300 text-white font-bold py-1 px-1 rounded"
                      >
                        <IoMedalOutline />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>

        {/* Right Section: Timer */}
        <section className="w-1/3 pl-4">
          <div className="bg-gray-200 dark:bg-zinc-800 rounded-lg p-4">
            <h2 className="text-xl text-center title font-bold dark:text-white mb-4">
              Countdown Timer
            </h2>
            <div className="mb-4">
              <p className="text-6xl font-bold text-center dark:text-white">{`${Math.floor(
                timeLeft / 60
              )
                .toString()
                .padStart(2, "0")}:${(timeLeft % 60)
                .toString()
                .padStart(2, "0")}`}</p>
            </div>
            <div className="flex flex-col items-center mb-4">
              <button
                onClick={startTimer}
                className="bg-[#3B8592] w-full hover:bg-[#3B8592]/80 text-white font-bold py-2 px-4 mb-2 rounded"
                disabled={isRunning || selectedTime === 0}
              >
                Start
              </button>
              <button
                onClick={pauseTimer}
                className="bg-yellow-500 w-full hover:bg-yellow-700 text-white font-bold py-2 px-4 mb-2 rounded"
              >
                Pause
              </button>
              <button
                onClick={resetTimer}
                className="bg-red-500 w-full hover:bg-red-700 text-white font-bold py-2 px-4 mb-4 rounded"
              >
                Reset
              </button>
            </div>
            <div className="flex justify-around">
              <button
                onClick={() => handleTimeSelection(60)}
                className={`${
                  selectedTime === 60
                    ? "bg-gray-700"
                    : "bg-gray-500 hover:bg-gray-700"
                } text-white font-bold py-1 px-1 rounded`}
              >
                1 min
              </button>
              <button
                onClick={() => handleTimeSelection(180)}
                className={`${
                  selectedTime === 180
                    ? "bg-gray-700"
                    : "bg-gray-500 hover:bg-gray-700"
                } text-white font-bold py-1 px-1 rounded`}
              >
                3 min
              </button>
              <button
                onClick={() => handleTimeSelection(300)}
                className={`${
                  selectedTime === 300
                    ? "bg-gray-700"
                    : "bg-gray-500 hover:bg-gray-700"
                } text-white font-bold py-1 px-1 rounded`}
              >
                5 min
              </button>
            </div>
            <div className="text-center py-8 px-4 text-gray-400 dark:text-zinc-600">
              <h3 className="alkacaps text-xl ">
                A game of Inquiry and Intrigue
              </h3>
              <p className="text-sm">Tip: Choose your questions carefully.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default MysteryBoxGamePage
